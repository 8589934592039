/* Default: 50x50 */
.Headshot {
  border-radius: 50%;
  height: 50px;
  object-fit: cover;
  padding: 0;
  width: 50px;
}

.shadow {
  box-shadow: 0 0 12px 0 rgba(193, 193, 193, 0.5);
}

.Headshot--100px {
  height: 100px;
  width: 100px;
}

/* Large: 100x100 */
.lg {
  height: 100px;
  width: 100px;
}

/* Large: 30x30 */
.xs {
  height: 30px;
  margin: 0;
  width: 30px;
}

@media (min-width: 768px) {
  .responsive.g {
    height: 150px;
    width: 150px;
  }
}
