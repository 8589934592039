.root {
  position: relative;
  display: grid;
  grid-template:
    'button'
    'menu';
  grid-template-rows: 100% auto;
  grid-template-columns: 100%;
}

.button {
  align-items: center;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  cursor: pointer;
  display: flex;
  font-size: 0.875rem;
  padding: 0.5rem 1rem;
  transition: 0.15s;
  grid-area: button;
}

.button:hover {
  border-color: #cfcfcf;
  background: #fafafa;
}

.open .button {
  background: #fafafa;
}

.chevron {
  margin-left: 0.5rem;
}

.menu {
  background: #fff;
  border-radius: 0.25rem;
  box-shadow: rgba(0, 0, 0, 0.08) 0px 0px 0px 1px,
    rgba(0, 0, 0, 0.3) 0px 4px 20px 0px;
  display: block;
  padding: 0;
  grid-area: menu;
}
