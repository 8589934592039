@import 'variables.scss';

.root {
  display: grid;
  grid-template:
    'logo nav'
    'sidebar main';
  grid-template-columns: minmax(min-content, 20rem) 1fr;
  grid-template-rows: 5rem auto;
  min-height: 100vh;
  font-size: 14px;
}

.sidebar {
  grid-area: sidebar;
}

.nav {
  grid-area: nav;
}

.main {
  grid-area: main;
  padding: 1rem;
}

.logo {
  align-items: center;
  background-color: $blue--dark;
  display: flex;
  grid-area: logo;
  padding: 1rem;
}

.logo img {
  max-height: 1.5rem;
}

h3 {
  color: #000000;
  font-family: Lato;
  font-size: 18px;
  font-weight: bold;
  letter-spacing: 1.8px;
  line-height: 24px;
  text-transform: uppercase;
}

h4 {
  color: #747b8d;
  line-height: 1.5rem;
  text-transform: uppercase;
}

@media (min-width: 768px) {
  .logo {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .main {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
