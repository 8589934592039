.scene {
  align-items: center;
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
}

.message {
  font-size: 1.25rem;
  margin-top: 1rem;
  max-width: 40rem;
}
