@import 'variables.scss';

.positionRow {
  background-color: #ffffff;
  display: grid;
  max-width: 100%;
  grid-template-columns: 65% auto 5%;
  height: 7rem;
  padding: 0.5rem 0.75rem;
  align-items: center;
}

.positionRowEdit {
  background-color: #ffffff;
  display: grid;
  max-width: 100%;
  grid-template-columns: 65% auto 10%;
  height: 7rem;
  padding: 0.5rem 0.75rem;
  align-items: center;
}
.headshot {
  height: 3rem;
  width: 3rem;
  border-radius: 50%;
  object-fit: cover;
  text-align: center;
}
.positionName {
  font-size: 1.2rem;
  font-weight: 800;
  padding-right: 1rem;
  max-height: 5.5rem;
}

.nameMarkdown {
  font-size: 1rem;
  font-weight: 600;
  color: $grey--dark;
}
.positionDescription {
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 5.5rem;
}

.positionItem {
  align-self: center;
}

.positionItemEdit {
  display: flex;
  flex-direction: column;
}

.editIcon {
  margin-left: 0.5rem;
  background-color: inherit;
  border: 0;
}
