@import 'variables.scss';

.root {
  animation: spin 1s ease-in-out infinite;
  border: 0.35rem solid $blue--light;
  border-radius: 50%;
  border-top-color: $blue--dark;
  display: inline-block;
  height: 3.5rem;
  width: 3.5rem;
}

.lg {
  border-width: 0.5rem;
  height: 5rem;
  width: 5rem;
}

.sm {
  border-width: 0.2rem;
  height: 2rem;
  width: 2rem;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
