$bg-primary: #f4f6f8;
$blue--dark: #2a5268;
$blue--edit: #4a90e2;
$blue--light: #a6b7c0;
$font-family-base: 'Lato', sans-serif;
$white: #ffffff;
$color-primary-red: #de4443;
$color-secondary: #1b536a;
$red--danger: #dc3545;
$grey--dark: #636363;
$grey--medium: #cbcbcb;
$grey--light: #e2e3e4;
$grey--text: #677a86;
$brand-disabled: rgba(177, 177, 177, 0.5);

/* Nav */
$nav-height: 3.5rem;
$nav-height--lg: 5rem;

/* etc */
$line-width: 0.2rem;
