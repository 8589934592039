@import 'variables.scss';

.candidateRow {
  background-color: #ffffff;
  display: flex;
  max-width: 100%;
  flex-direction: row;
  height: 3rem;
  padding: 1rem;
  justify-content: flex-start;
  align-items: center;
}

.candidateRow:nth-child(even) {
  background-color: #ecf0f3;
}

.candidateLink {
  color: #4a90e2;
  text-decoration: none;
  background-color: inherit;
  font-weight: 600;
  font-size: 1.2rem;
  border: none;
  cursor: pointer;
  font-family: inherit;
}

.candidateInfo {
  display: flex;
  flex-direction: column;
  align-content: center;
  margin-left: 1.5rem;
}
