@import '../../variables.scss';

.root {
  background-color: $white;
  box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
  display: grid;
  grid-template:
    'electionSwitcher searchBar userMenu'
    'searchForm searchForm searchForm';
  grid-template-columns: 40% auto auto;
  grid-template-rows: 100% 120%;
  align-items: center;
}

.electionSwitcher {
  grid-area: electionSwitcher;
  height: 100%;
}

.searchBar {
  grid-area: searchBar;
  height: 100%;
}

.userMenu {
  grid-area: userMenu;
  height: 100%;
}

.searchForm {
  grid-area: searchForm;
  visibility: hidden;
  z-index: 100;
  height: 100%;
}

.faded {
  background-color: #f3f3f3;
  opacity: 0.8;
}

.visible {
  visibility: visible;
}
