@import '../../variables';

.root {
  background-color: $blue--dark;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 1rem;
}

.nav {
  margin-top: 2.5rem;
}

.link {
  color: $white;
  display: block;
  font-size: 1.125rem;
  margin: 0 -1rem;
  padding: 0.5rem 1rem;
  text-decoration: none;

  &:hover {
    background-color: scale-color($color: $blue--dark, $lightness: 10%);
  }
}

.activeLink {
  background-color: scale-color($color: $blue--dark, $lightness: 10%);
}

@media (min-width: 768px) {
  .root {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .link {
    margin-left: -2rem;
    margin-right: -2rem;
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
