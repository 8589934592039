@import 'variables.scss';

.editPositionRow {
  display: grid;
  grid-template-columns: 60% 40%;
  grid-template-rows: 25% 30% 30% 15%;
  grid-template-areas:
    'originalName customDescription'
    'locale customDescription'
    'customName customDescription'
    '. buttons';
  background: #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
  padding: 0.75rem 1rem;
  align-items: center;
  justify-items: stretch;
}
.positionItem {
  padding: 1rem;
}
.originalName {
  grid-area: originalName;
  font-size: 1.2rem;
  font-weight: 800;
}

.locale {
  grid-area: locale;
}

.localeSelect {
  margin-top: 0.25rem;
}

.customDescription {
  grid-area: customDescription;
  align-self: start;
  width: 85%;
}

.descriptionText {
  height: 10rem;
  width: 100%;
}

.customName {
  grid-area: customName;
}

.input {
  border-radius: 3px;
  padding: 0.75rem 0.5rem;
  border: 1px solid #dedede;
  display: block;
  margin-top: 0.25rem;
}
.nameInput {
  width: 90%;
  height: 1rem;
}

.label {
  font-weight: 600;
}

.buttons {
  grid-area: buttons;
  justify-self: end;
}

.saveButton {
  background-color: $blue--dark;
  color: #ffffff;
  width: 6rem;
  padding: 0.5rem;
  margin-left: 0.5rem;
}

.cancelButton {
  background-color: $red--danger;
  color: #ffffff;
  width: 6rem;
  padding: 0.5rem;
}
