.container {
  align-items: center;
  border-radius: 0.25rem;
  display: flex;
  width: 100%;
  font-weight: 400;
  color: #9b9b9b;
  border: none;
  background-color: inherit;
  text-decoration: none;
  cursor: pointer;
  height: 100%;
  border-left: 1px solid #dedede;
}

.icon {
  margin-right: 0.75rem;
}
