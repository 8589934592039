.form {
  align-items: center;
  background-color: #ffffffff;
  display: grid;
  grid-template:
    'keywordLabel dropdownLabel .'
    'keyword dropdown button';
  grid-template-columns: 60% 15% 15%;
  grid-template-rows: 30% 70%;
  padding: 0.5rem 1rem;
  justify-content: space-between;
  box-shadow: 0 7px 7px 0 rgba(146, 146, 146, 0.5);
}

.icon {
  margin-right: 0.75rem;
}

.input {
  background: transparent;
  outline: none;
  border: 1px solid #eaeaea;
  flex: 1;
  font-size: 1rem;
  padding: 0.75rem 0.5rem;
  width: 100%;
  box-shadow: 0 0 7px 0 rgba(146, 146, 146, 0.5);
  margin: 0.5rem 0;
}

.input::placeholder {
  font-style: italic;
}

.keyword {
  grid-area: keyword;
}
.dropdown {
  grid-area: dropdown;
}
.button {
  grid-area: button;
}

.label {
  text-transform: uppercase;
  color: #9ba5af;
  font-weight: 600;
  font-size: 0.75rem;
}

.keywordLabel {
  grid-area: keywordLabel;
  align-items: end;
}

.dropdownLabel {
  grid-area: dropdownLabel;
  align-items: end;
}
