@import '../../../variables';

.root {
  margin: 0 -1rem;
}

.root.open {
  background-color: scale-color($color: $blue--dark, $lightness: 10%);
}

.title {
  align-items: center;
  color: $white;
  cursor: pointer;
  display: flex;
  font-size: 1.125rem;
  justify-content: space-between;
  padding: 0.5rem 1rem;
  text-decoration: none;
  user-select: none;

  &:hover {
    background-color: scale-color($color: $blue--dark, $lightness: 10%);
    color: $white;
  }
}

.link {
  color: $white;
  display: block;
  font-size: 1rem;
  font-weight: 300;
  padding: 0.5rem 2rem;
  position: relative;
  text-decoration: none;

  &:hover {
    background-color: scale-color($color: $blue--dark, $lightness: 20%);
  }
}

.activeLink {
  background-color: scale-color($color: $blue--dark, $lightness: 20%);

  &::before {
    content: '●';
    left: 1rem;
    position: absolute;
  }
}

@media (min-width: 768px) {
  .root {
    margin-left: -2rem;
    margin-right: -2rem;
  }

  .link {
    padding-left: 3rem;
  }

  .activeLink {
    &::before {
      left: 2rem;
    }
  }

  .title {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}
