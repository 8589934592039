@import 'variables.scss';

.container {
  width: 100%;
  padding: 1rem;
}

.filterForm {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
}

.field {
  width: 10rem;
  min-width: 7rem;
  box-shadow: 0 2px 4px 0 rgba(177, 177, 177, 0.5);
}

.filterContainer {
  display: flex;
  flex-direction: column;
}

.applyButton {
  background-color: $blue--dark;
  color: #ffffff;
  width: 6rem;
  padding: 0.5rem;
  margin-left: 0.5rem;
  max-height: 3rem;
  align-self: flex-end;
}

.nameInput {
  border: 1px solid #eaeaea;
  height: 100%;
  border-radius: 4px;
  padding: 0.25rem 0.5rem;
}

.label {
  font-weight: 600;
  color: #9ba5af;
  text-transform: uppercase;
  margin-bottom: 1rem;
}
