@import 'variables.scss';

.container {
  width: 100%;
  height: 2rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}
.pageNumber {
  height: 2rem;
  width: 2rem;
  margin: 0 0.25rem 0 0.25rem;
  color: $grey--dark;
  font-weight: 600;
  border: 0;
  background-color: transparent;
}
